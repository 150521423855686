jQuery(document).ready(function () {
  




  jQuery(".swiper-load-fade").each(function (i, obj) {
    var swiper_section = jQuery(this);
    swiper_section.imagesLoaded(function () {
      swiper_section.addClass('swiper-loaded');
    });
  });





  var controller = new ScrollMagic.Controller();

  var animateBackgroundWord = Array.prototype.slice.call(
    document.querySelectorAll(".snug-to-margin")
  );

  var self = this;

  animateBackgroundWord.forEach(function(self) {
    var text = self.querySelectorAll(".bg-effect");

    var sceneOne = new TimelineMax();

    sceneOne.fromTo(text, 0.5, { y: 130 }, { y: -150 });

    var scene1 = new ScrollMagic.Scene({
      triggerElement: self,
      triggerHook: "onEnter",
      duration: 10000,
      offset: 0
    })
      .setTween(sceneOne)
      .setClassToggle(self, "active")
      .addTo(controller);
  });

  var animateGrid = Array.prototype.slice.call(
    document.querySelectorAll(".grid-fade")
  );

  var self = this;

  animateGrid.forEach(function(self) {
    var grid = self.querySelectorAll(".grid-fade .row .grid-item");

    var gridScene = new TimelineMax();

    gridScene.fromTo(
      grid,
      0.8,
      { opacity: 0, y: 20 },
      {
        opacity: 1,
        y: 0,
        stagger: 0.1
      }
    );

    var grid_scene = new ScrollMagic.Scene({
      triggerElement: self,
      triggerHook: "onEnter",
      duration: 0,
      offset: 150
    })
      .setTween(gridScene)
      .setClassToggle(self, "active")
      .addTo(controller);
  });

  var animateGrid = Array.prototype.slice.call(
    document.querySelectorAll(".text-fade")
  );

  animateGrid.forEach(function(self) {
    var two_column_headings = self.querySelectorAll(
      ".text-fade h1, .text-fade h2, .text-fade h3, .text-fade h4, .text-fade .sub"
    );
    var two_column_p = self.querySelectorAll(
      ".text-fade p, .text-fade ul, .text-fade ol"
    );
    var two_column_button = self.querySelectorAll(".text-fade a.butt");

    var two_column_hr = self.querySelectorAll(
      ".text-fade hr.mini"
    );

    var twoColumnScene = new TimelineMax();

    twoColumnScene
      .fromTo(
        two_column_headings,
        0.8,
        { opacity: 0, y: 10 },
        {
          opacity: 1,
          y: 0
        }
      )
      .fromTo(
        two_column_p,
        0.8,
        { opacity: 0, y: 10 },
        {
          opacity: 1,
          y: 0
        },
        0.25,
        "-=.25"
      )
      .fromTo(
        two_column_button,
        0.8,
        { opacity: 0, y: 10 },
        {
          opacity: 1,
          y: 0
        },
        0.25,
        "-=.25"
    )
      .fromTo(
        two_column_hr,
        0.8,
        { opacity: 0, y: 10, width: 0 },
        {
          opacity: 1,
          y: 0,
          width: 30
        },
        0.25,
        "-=.25"
      );

    var two_column_scene = new ScrollMagic.Scene({
      triggerElement: self,
      triggerHook: "onEnter",
      duration: 0,
      offset: 150
    })
      .setTween(twoColumnScene)
      .setClassToggle(self, "active")
      .addTo(controller);
  });






  var animateTwoColumn = Array.prototype.slice.call(
    document.querySelectorAll(".two-col-fade")
  );

  animateTwoColumn.forEach(function(self) {
    var two_column_col1 = self.querySelectorAll(
      ".two-col-fade .col-first .inner"
    );
    var two_column_col2 = self.querySelectorAll(
      ".two-col-fade .col-second"
    );

    var twoColumnScene = new TimelineMax();

    twoColumnScene
      .fromTo(
        two_column_col1,
        0.8,
        { opacity: 0, x: -10 },
        {
          opacity: 1,
          x: 0
        }
      )
      .fromTo(
        two_column_col2,
        0.8,
        { opacity: 0, x: 10 },
        {
          opacity: 1,
          x: 0
        },
        0.25,
        "-=.25"
      );

    var two_column_scene = new ScrollMagic.Scene({
      triggerElement: self,
      triggerHook: "onEnter",
      duration: 0,
      offset: 150
    })
      .setTween(twoColumnScene)
      .setClassToggle(self, "active")
      .addTo(controller);
  });




  // ANimate the timeline
  var animateGrid = Array.prototype.slice.call(
    document.querySelectorAll("section.timeline .row .timeline-entry")
  );

  animateGrid.forEach(function(self) {
    //var grid = self.querySelectorAll("section.timeline .row .timeline-entry");
    var entry = self;

    var timelineScene = new TimelineMax();

    timelineScene.fromTo(
      entry,
      0.8,
      { opacity: 0, y: 20 },
      {
        opacity: 1,
        y: 0,
        stagger: 0.1
      }
    );

    var tl_scene = new ScrollMagic.Scene({
      triggerElement: self,
      triggerHook: "onEnter",
      duration: 0,
      offset: 150
    })
      .setTween(timelineScene)
      .setClassToggle(self, "active")
      .addTo(controller);
  });
});
